import { Row, Col } from "reactstrap";
import Wrapper from "../Wrapper";

const img =
  "https://images.unsplash.com/photo-1495314736024-fa5e4b37b979?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1052&q=80";

function Footer() {
  const contentData = [
    {
      msg:
        "Very professional and talented company. They were able to understand our brand quickly and delivered within Timeframe.",
      client: "Dhruval Ramani",
      project: "The Networker",
    },
    {
      msg:
        "Great Developers.  Got the project done on time and within Budgets. Looking forward to working with them for upcoming projects.",
      client: "Piyush",
      project: "Hometary",
    },
    {
      msg:
        "I had a great experience working with these guys. The project was more fun than a headache. I've had the best experience with these guys.",
      client: "Komal",
      project: "KTMG",
    },
  ];

  return (
    <Wrapper backgroundImage={img}>
      <div className="content-footer">
        <div className="item mb-3">
          <p className="msg">
            iblocklabs is a professional agency rooted in building your dreams that drives your core
            business. Our focus is to deliver better results today that will help you to lead future
            and to make a healthy impact in industry.
          </p>
        </div>
        <div className="item">
          <Row form>
            <Col sm={6}>
              <p className="list-title">Services</p>
              <ul>
                <li>App Development</li>
                <li>Web Development</li>
                <li>UI/UX Design</li>
              </ul>
            </Col>
            <Col sm={6}>
              <p className="list-title">Industries</p>
              <ul>
                <li>Food</li>
                <li>E-commercet</li>
                <li>Social Networking</li>
                <li>Company/Personal Portfolio</li>
                <li>Management Panels</li>
              </ul>
            </Col>
          </Row>
        </div>
      </div>
    </Wrapper>
  );
}

export default Footer;
