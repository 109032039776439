import Slider from "react-slick";
import Wrapper from "../Wrapper";

const img =
  "https://images.unsplash.com/photo-1550684376-efcbd6e3f031?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80";

const Arrow = () => (
  <svg fill="currentColor" viewBox="0 0 21 41">
    <path d="M20.3 40.8L0 20.5 20.3.2l.7.7L1.3 20.5 21 40.1z"></path>
  </svg>
);

function AppSlide({ images }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: (
      <button className="slick-arrow slick-next">
        <Arrow />
      </button>
    ),
    prevArrow: (
      <button className="slick-arrow slick-prev">
        <Arrow />
      </button>
    ),
  };
  return (
    <Wrapper backgroundImage={img}>
      <Slider className="app-slider" {...settings}>
        {images.map(({ image }, index) => (
          <div>
            <img className="w-100" src={image} alt="" />
          </div>
        ))}
      </Slider>
    </Wrapper>
  );
}
export default AppSlide;
