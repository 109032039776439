import { FormGroup, Input, Label } from "reactstrap";
import { ErrorMessage, Field } from "formik";

function InputComponent(props) {
  const { label = "", name, type = "text", placeholder = "" } = props;
  return (
    <FormGroup>
      {label && <Label>{label}</Label>}
      <Field as={Input} type={type} name={name} placeholder={placeholder} />
      <ErrorMessage
        name={name}
        component="div"
        className="text-danger"
        style={{ fontSize: ".7em" }}
      />
    </FormGroup>
  );
}

export default InputComponent;
