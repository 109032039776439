import { Row, Col } from "reactstrap";
import Wrapper from "../Wrapper";

const img =
  "https://images.unsplash.com/photo-1551892589-865f69869476?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80";

function Testimonial() {
  const contentData = [
    {
      msg:
        "Very professional and talented company. They were able to understand our brand quickly and delivered within Timeframe.",
      client: "Dhruval Ramani",
      project: "The Networker",
    },
    {
      msg:
        "Great Developers.  Got the project done on time and within Budgets. Looking forward to working with them for upcoming projects.",
      client: "Piyush",
      project: "Hometary",
    },
    {
      msg:
        "I had a great experience working with these guys. The project was more fun than a headache. I've had the best experience with these guys.",
      client: "Komal",
      project: "KTMG",
    },
  ];

  return (
    <Wrapper backgroundImage={img}>
      <div className="content-testimonial">
        <h2 className="m-title">What We Do</h2>
        <Row form className="justify-content-center">
          {contentData.map(({ msg, client, project }, index) => (
            <Col sm={4} key={index} className="mb-2">
              <div className="item">
                <p className="msg">{msg}</p>
                <p className="client">{client}</p>
                <p className="project">{project}</p>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </Wrapper>
  );
}

export default Testimonial;
