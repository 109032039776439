import { Row, Col } from "reactstrap";
import Wrapper from "../Wrapper";
import crmIcon from "../../../../assets/images/icons/crm.webp";
import happyIcon from "../../../../assets/images/icons/happy.webp";
import seoIcon from "../../../../assets/images/icons/seo.webp";
import speedIcon from "../../../../assets/images/icons/speed.webp";

const img =
  "https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1052&q=80";

function Intro() {
  const contentData = [
    {
      icon: seoIcon,
      title: "Projects delivered",
      value: "25+",
    },
    {
      icon: speedIcon,
      title: "Hours Worked",
      value: "4200+",
    },
    {
      icon: happyIcon,
      title: "Happy Clients",
      value: "12+",
    },
    {
      icon: crmIcon,
      title: "Repetitive clients",
      value: "100%",
    },
  ];

  return (
    <Wrapper backgroundImage={img}>
      <div className="content-intro">
        <Row form>
          {contentData.map(({ icon, title, value }, index) => (
            <Col xs={6} lg={3} key={index} className="mb-2">
              <div className="intro-item">
                <img src={icon} alt={title} />
                <h2>{value}</h2>
                <p>{title}</p>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </Wrapper>
  );
}

export default Intro;
