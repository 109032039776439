import { Row, Col } from "reactstrap";
import Wrapper from "../Wrapper";

import androidIcon from "../../../../assets/images/icons/android.svg";
import angularIcon from "../../../../assets/images/icons/angular.svg";
import googleCloudIcon from "../../../../assets/images/icons/google-cloud.svg";
import iosIcon from "../../../../assets/images/icons/ios.svg";
import kubernetsIcon from "../../../../assets/images/icons/kubernets.svg";
import nodeJsIcon from "../../../../assets/images/icons/node-js.svg";
import mongoDbIcon from "../../../../assets/images/icons/mongo-db.svg";
import reactIcon from "../../../../assets/images/icons/react.svg";
import redisIcon from "../../../../assets/images/icons/redis.svg";

const img =
  "https://images.unsplash.com/photo-1515704089429-fd06e6668458?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1050&q=80";

function OurFulltackCapabilities() {
  const contentData = [
    { icon: androidIcon },
    { icon: angularIcon },
    { icon: googleCloudIcon },
    { icon: iosIcon },
    { icon: kubernetsIcon },
    { icon: nodeJsIcon },
    { icon: mongoDbIcon },
    { icon: reactIcon },
    { icon: redisIcon },
  ];

  return (
    <Wrapper backgroundImage={img}>
      <div className="content-full-stack">
        <h2 className="m-title">Our Full-stack Capabilities</h2>
        <Row form className="justify-content-center">
          {contentData.map(({ icon, title, dec }, index) => (
            <Col key={index} className="mb-2">
              <div className="item">
                <img src={icon} alt={title} />
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </Wrapper>
  );
}

export default OurFulltackCapabilities;
