import { useEffect, useRef } from "react";
import Slider from "react-slick";
import logo from "../../assets/images/logo.png";
import homeContent from "./content";
import { createGlobalStyle } from "styled-components";

const ArrowBottom = () => (
  <svg
    width="1em"
    height="1em"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    viewBox="0 0 512.011 512.011"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path d="M505.755,123.592c-8.341-8.341-21.824-8.341-30.165,0L256.005,343.176L36.421,123.592c-8.341-8.341-21.824-8.341-30.165,0    s-8.341,21.824,0,30.165l234.667,234.667c4.16,4.16,9.621,6.251,15.083,6.251c5.462,0,10.923-2.091,15.083-6.251l234.667-234.667    C514.096,145.416,514.096,131.933,505.755,123.592z" />
  </svg>
);

function Home() {
  const sliderRef = useRef(null);
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    vertical: true,
    draggable: false,
  };

  function slide(y) {
    y < 0 ? onNext() : onPrev();
  }

  const onNext = () => sliderRef.current.slickNext();
  const onPrev = () => sliderRef.current.slickPrev();

  useEffect(() => {
    let scrollCount = null;
    let scroll = null;
    let scrollDelay = 100;

    window.addEventListener("wheel", (e) => {
      clearTimeout(scroll);
      scroll = setTimeout(function () {
        scrollCount = 0;
      }, scrollDelay);
      if (scrollCount) return 0;
      scrollCount = 1;

      // console.log("razat", e);
      console.log("razat", e.wheelDelta);
      slide(e.wheelDelta);
    });

    return () => {
      window.removeEventListener("wheel");
    };
  });

  return (
    <>
      <GlobalStyle />
      <img src={logo} alt="logo" className="home-logo" />
      <Slider ref={sliderRef} className="page-slider" {...settings}>
        {homeContent.map(({ content }, index) => (
          <div key={index}>{content}</div>
        ))}
      </Slider>
      <button onClick={onNext} className="slide-next-bottom">
        <ArrowBottom />
      </button>
    </>
  );
}

export default Home;

const GlobalStyle = createGlobalStyle`
body{
  overflow:hidden
}`;
