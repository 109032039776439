import { Container } from "reactstrap";

function Footer() {
  return (
    <footer id="footer">
      <Container></Container>
    </footer>
  );
}

export default Footer;
