import { useEffect } from "react";
import Routes from "./routes";
import AOS from "aos";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
