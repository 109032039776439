import { useState } from "react";
import { Form, Button, Spinner, Row, Col, Alert } from "reactstrap";
import InputComponent from "../InputComponent";
import { Formik } from "formik";
import * as yup from "yup";

function ContactForm() {
  const [isError, setIsError] = useState("");
  const [isSuccess, setIsSuccess] = useState("");

  const clearAlerts = () => {
    isError !== "" && setIsError("");
    isSuccess !== "" && setIsSuccess("");
  };

  function onSubmit(values, { setSubmitting, resetForm }) {
    const { name, email, phone, message } = values;

    clearAlerts();

    setTimeout(() => {
      setSubmitting(false);
      setIsSuccess("Your message successfully delivered. We will reach soon...");
      resetForm();
    }, 3000);
  }

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  };

  const validationSchema = yup.object({
    firstName: yup.string().required().label("First Name"),
    lastName: yup.string().required().label("Last Name"),
    email: yup.string().email().required().label("Email"),
    phone: yup.string().min(10).max(10).required().label("Phone"),
  });

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values, isSubmitting }) => (
        <Form>
          {isError && <Alert color="danger">{isError}</Alert>}
          {isSuccess && <Alert color="success">{isSuccess}</Alert>}
          <Row>
            <Col md={6}>
              <InputComponent label="First Name" name="firstName" />
            </Col>
            <Col md={6}>
              <InputComponent label="Last Name" name="lastName" />
            </Col>
            <Col md={6}>
              <InputComponent type="email" label="Email" name="email" />
            </Col>
            <Col md={6}>
              <InputComponent label="Phone" name="phone" />
            </Col>
          </Row>

          <InputComponent
            type="textarea"
            label="Message"
            name="message"
            placeholder="Tell us about your project..."
          />
          <div className="text-center">
            {isSubmitting && (
              <div className="mb-2">
                <Spinner color="primary" />
              </div>
            )}
            <Button className="px-4" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
              Submit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ContactForm;
