import theNetwork1 from "../../../assets/images/appSlides/the-network-1.webp";
import theNetwork2 from "../../../assets/images/appSlides/the-network-2.webp";
import theNetwork3 from "../../../assets/images/appSlides/the-network-3.webp";
import theNetwork4 from "../../../assets/images/appSlides/the-network-4.webp";

import hometary1 from "../../../assets/images/appSlides/hometary-1.webp";
import hometary2 from "../../../assets/images/appSlides/hometary-2.webp";
import hometary3 from "../../../assets/images/appSlides/hometary-3.webp";
import hometary4 from "../../../assets/images/appSlides/hometary-4.webp";

import ktmg1 from "../../../assets/images/appSlides/ktmg-1.webp";
import ktmg2 from "../../../assets/images/appSlides/ktmg-2.webp";
import ktmg3 from "../../../assets/images/appSlides/ktmg-3.webp";
import ktmg4 from "../../../assets/images/appSlides/ktmg-4.webp";

export const theNetwork = [
  { image: theNetwork1 },
  { image: theNetwork2 },
  { image: theNetwork3 },
  { image: theNetwork4 },
];
export const hometary = [
  { image: hometary1 },
  { image: hometary2 },
  { image: hometary3 },
  { image: hometary4 },
];

export const ktmg = [{ image: ktmg1 }, { image: ktmg2 }, { image: ktmg3 }, { image: ktmg4 }];
