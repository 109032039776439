import { Row, Col } from "reactstrap";
import Wrapper from "../Wrapper";
import OfficeIcon from "../../../../assets/images/icons/at-the-office-amico.webp";
import remotelyIcon from "../../../../assets/images/icons/working-remotely-rafiki.webp";
import realTimeSyncPanaIcon from "../../../../assets/images/icons/real-time-sync-pana.webp";

const img =
  "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80";

function WhatWeDo() {
  const contentData = [
    {
      icon: OfficeIcon,
      title: "End-to-end Product Development",
      dec: "Starting from Inception to sustenance.",
    },
    {
      icon: remotelyIcon,
      title: "Remote Manage Hires",
      dec: "We know how to manage remote workers, work directly with qualified developers.",
    },
    {
      icon: realTimeSyncPanaIcon,
      title: "Cloud Consulting",
      dec: "Cloud management migration and enable services.",
    },
  ];

  return (
    <Wrapper backgroundImage={img}>
      <div className="content-what-we-do">
        <h2 className="m-title">What We Do</h2>
        <Row form className="justify-content-center">
          {contentData.map(({ icon, title, dec }, index) => (
            <Col sm={4} key={index} className="mb-2">
              <div className="item">
                <img src={icon} alt={title} />
                <h2>{title}</h2>
                <p>{dec}</p>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </Wrapper>
  );
}

export default WhatWeDo;
