import { Container } from "reactstrap";

function Header() {
  return (
    <header id="header">
      <Container></Container>
    </header>
  );
}
export default Header;
