import { Row, Col, Button } from "reactstrap";
import Wrapper from "../Wrapper";
import bannerImage from "../../../../assets/images/banner/banner.webp";

const img =
  "https://images.unsplash.com/photo-1613365891364-887bbfc9c723?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80";

function Banner() {
  return (
    <Wrapper backgroundImage={img}>
      <div className="content-banner">
        <Row className="align-items-center">
          <Col md={6}>
            <h1>Your extended Cloud-Native Development Team.</h1>
            <p>
              We are your extended team to Harness the Full-stack capabilities of Cloud Native
              Technologies.
            </p>
            <Button color="primary" className="mr-2">
              Explore Projects
            </Button>
            <Button color="primary">Contact Us</Button>
          </Col>
          <Col md={6}>
            <img src={bannerImage} alt="" className="img-fluid" />
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
}

export default Banner;
