import Layout from "../components/layout";
import { Home } from "../pages";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

function Routes() {
  return (
    <Router>
      <Switch>
        <Layout>
          <Route exact path="/" component={Home} />
        </Layout>
      </Switch>
    </Router>
  );
}

export default Routes;
